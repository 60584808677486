/* UTILITIES
-------------------------------------------------- */

.float-right {
  float:right;
}

.float-left {
  float:left;
}

.padding-top-5{
  padding-top: 5px;
}

.padding-top-10{
  padding-top: 10px;
}

.padding-top-20{
  padding-top: 20px;
}

.padding-top-40{
  padding-top: 40px;
}

.padding-top-80{
  padding-top: 80px;
}

.padding-bottom-5{
  padding-bottom: 5px;
}

.padding-bottom-10{
  padding-bottom: 10px;
}

.padding-bottom-20{
  padding-bottom: 20px;
}

.padding-bottom-40{
  padding-bottom: 40px;
}

.padding-bottom-80{
  padding-bottom: 80px;
}


.margin-top-5{
  margin-top: 5px;
}

.margin-top-10{
  margin-top: 10px;
}

.margin-top-20{
  margin-top: 20px;
}

.margin-top-40{
  margin-top: 40px;
}

.margin-top-80{
  margin-top: 80px;
}

.margin-bottom-5{
  margin-bottom: 5px;
}

.margin-bottom-10{
  margin-bottom: 10px;
}

.margin-bottom-20{
  margin-bottom: 20px;
}

.margin-bottom-40{
  margin-bottom: 40px;
}

.margin-bottom-80{
  margin-bottom: 80px;
}

.margin-left-5{
  margin-left: 5px;
}

.margin-left-10{
  margin-left: 10px;
}

.margin-left-20{
  margin-left: 20px;
}

.margin-left-40{
  margin-left: 40px;
}

.margin-left-80{
  margin-left: 80px;
}

.margin-right-5{
  margin-right: 5px;
}

.margin-right-10{
  margin-right: 10px;
}

.margin-right-20{
  margin-right: 20px;
}

.margin-right-40{
  margin-right: 40px;
}

.margin-right-80{
  margin-right: 80px;
}

.text-align-left {
  text-align:left;
}

.text-align-center {
  text-align:center;
}

.text-align-right {
  text-align:right;
}

.line {
  display: inline-block;
}


