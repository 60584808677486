/* CUSTOMIZE THE NAVBAR
-------------------------------------------------- */


@media all {

	.navbar-brand img {
	margin-top: -5px;
	}

	.btn-headnav{
	margin-top: -20px;
	margin-bottom: -15px;
	}

	.btn-headnav-login{
	margin-left: 30px;
	}

	.btn-headnav-signup{
	margin-left: -25px;
	}
}




/* RESPONSIVE CSS
-------------------------------------------------- */


/* Smartphones (portrait and landscape) ----------- */
@media only screen
and (min-width : 400px)
and (max-width : 766px) {


}


/* iPads (portrait and landscape) ----------- */
@media only screen
and (min-width : 767px)
and (max-width : 1024px) {


}


/* Desktops and laptops ----------- */
@media only screen
and (min-width : 1025px) {

}

/* iPhone 4 ----------- */
@media only screen
and (min-width : 280px)
and (max-width : 399px) {

}
