/* GLOBAL STYLES
-------------------------------------------------- */

@media all {

	.color-grey{
		color: $grey;}

	.white-layer{
		background-color: $transparent-layer;}

	.white-text{
		color: $white !important;
	}

	.small-text-grey{
		color:$light-grey !important;
	}

	.orange-wrapper{
		background-color: $orange;
	}

	.blue-wrapper{
		background-color: $dark-blue;
	}

}


/* Smartphones (portrait and landscape) ----------- */
@media only screen
and (min-width : 400px)
and (max-width : 767px) {


}

/* iPads (portrait and landscape) ----------- */
@media only screen
and (min-width : 768px)
and (max-width : 1024px) {


}

/* Desktops and laptops ----------- */
@media only screen
and (min-width : 1025px) {


}

/* Large screens -----------
@media only screen
and (min-width : 1824px) {
}*/

/* iPhone 4 ----------- */
@media only screen
and (min-width : 280px)
and (max-width : 399px) {


}
